import React from "react"
import Layout from "../components/layout"
import HelmetEx from "../components/helmet-ex"

export const accessPolicy = () => {
  return (
    <Layout>
      <section className="access-policy-section">
        <HelmetEx>
          <div metadata="title">Accessibility</div>
          <div metadata="keywords">
            accessibility, indoor cycle, interactive bike, streaming classes,
            virtual classes, stryde, stryde bike, virtual workouts
          </div>
          <div metadata="path">/faq</div>
          <div metadata="description">
          Making our website's content accessible and user friendly to everyon
          </div>
        </HelmetEx>
        <div className="container">
          <h4>Our Commitment to Accessibility</h4>
          <p>
            Katana Fitness Inc.is committed to making our website's content accessible and user friendly to everyone. If you are having difficulty viewing or navigating the content on this website, or notice any content, feature, or functionality that you believe is not fully accessible to people with disabilities, please call our Customer Service team at (888) 418-0143 or email our team at help@strydebike.com with “Disabled Access” in the subject line and provide a description of the specific feature you feel is not fully accessible or a suggestion for improvement. We take your feedback seriously and will consider it as we evaluate ways to accommodate all of our customers and our overall accessibility policies.  Additionally, while we do not control such vendors, we strongly encourage vendors of third-party digital content to provide content that is accessible and user friendly.


          </p>
        
        </div>
      </section>
    </Layout>
  )
}
export default accessPolicy
